.body{
  background-color: #b2aba9;
}
.donation-main {
  width: 100%;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  padding-bottom: 60px;
}
.donation-heading > p {
  color: #212529;
  font-size: 20px;
  padding: 16px;
  text-align: center;
  /* background-color: aquamarine; */
}
.donation-qr {
  width: 300px;
  /* height: 300px; */
  margin: auto;
  margin-top: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.donation-qr > img {
  width: 100%;
  height: 100%;
}
