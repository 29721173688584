.HeadingNavbar-main {
  width: 100%;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: space-around;
  /* border: 1px solid grey; */
  padding-top: 15px;
  padding-bottom: 15px;
}
@media only screen and (max-width: 600px) {
  .hideOnMobile {
    display: none;
  }
}
.HeadingNavbar-logo {
  max-width: 300px;
  padding: 10px;
}
.HeadingNavbar-logo > img {
  width: 100%;
}
.HeadingNavbar-lang {
  cursor: pointer;
  display: flex;
  gap: 5px;
}
.HeadingNavbar-lang > p {
  cursor: pointer;
}
@media (max-width: 970px) {
  .HeadingNavbar-main {
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    gap: 10px;
  }
}
