.program-main {
  width: 100%;
  padding-bottom: 50px;
}
.program-main-program {
  width: 98%;
  margin-left: 16px; 
  margin-top:4px;
}
.program-main-program1 {
  width: 100%;
  height: 100%;
}
.program-table {
  width: 92%;
  border: 1px solid #dee2e6;
}
.program-tableHead {
  width: 100%;
  background-color: #343a40;
  color: white;
  text-align: center;
  height: 60px;
  border: 1px solid #dee2e6;
}
.program-tr {
  height: 55px !important;
  border: 1px solid #dee2e6;
}
.program-tr1 {
  height: 55px !important;
  background-color: #f2f2f2;
  border: 1px solid #dee2e6;
}
.program-tr:hover {
  background-color: #f2f2f2;
}
@media (max-width: 1000px) {
  .program-main-program1 {
    
    height: 100%;
  }
}
