.MainBanner-main {
  /* width: 100%; */
  max-width: 100%;
  height: 500px;
  overflow: hidden;
  /* border: 1px solid red; */
}

.MainBanner-img {
  width: 100%;
  max-width: 100%;
}

.a {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

@media (max-width: 970px) {
  .MainBanner-main {
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .MainBanner-img {
    width: 100%;
    max-width: 100%;
  }
}

.slick-prev:before,
.slick-next:before {
  color: black;
}
