.temple-main {
  width: 100%;
  padding-bottom: 50px;
}
.temple-main-heading {
  width: 70%;
  margin: 16px;
  margin-top: 30px;
}
.temple-main-heading > span {
  color: #e2534c;
  font-weight: 400;
  font-size: 20px;
  position: relative;
  margin-bottom: 40px;
  
}
.temple-main-heading > span::after {
  position: absolute;
  content: "";
  background-color: #000;
  height: 1px;
  width: 58px;
  left: 0;
  bottom: -10px;
}
.temple-main-details1 {
  background-color: #f1f1f1;
  width: 70%;
  height: fit-content;
  margin: auto;
  margin-top: 40px;
  display: flex;
  padding: 30px;
  gap: 30px;
}
.temple-main-details1-img {
  width: 40%;
}
.temple-main-details1-text {
  width: 60%;
}
.temple-main-details1 > div > img {
  width: 400px;
  border: 3px solid #f1f1f1;
  box-shadow: 0px 0px 7px #999;
}

.temple-main-details1 p:nth-child(1) {
  font-size: 20px;
  color: #e2534c;
  font-weight: 400;
}
.temple-main-details1 p:nth-child(2) {
  font-size: 14px;
  color: #666;
  font-weight: 400;
}
.temple-main-details1 p:nth-child(3) {
  font-size: 15px;
  color: #666;
  font-weight: 400;
  padding-top: 10px;
}
.temple-main-details2 {
  background-color: #f1f1f1;
  width: 70%;
  margin: auto;
  height: fit-content;
  margin-top: 30px;
  padding: 40px;
}
.temple-main-details2 > ol {
  font-size: 16px;
  color: #212529;
}
@media (max-width: 970px) {
  .temple-main-details1 {
    flex-direction: column;
  }
  .temple-main-details1-img {
    width: 100%;
  }
}
