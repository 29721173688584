.KeywordHeader-main {
  width: 100%;
  background: #f1f1f1;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  margin: auto;
}

.navbar-nav-links-main {
  width: 65%;
  display: flex;
  margin: auto;
  justify-content: space-around;
}
.navbar-nav-links-main > a {
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  color: #333;
  font-family: "HelveticaNeue", "Helvetica Neue", "Helvetica-Neue", Helvetica,
    Arial, sans-serif;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background: #333;
  margin: 4px 0;
}

.navbar-nav-links-main {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 970px) {
  .menu-toggle {
    display: flex;
  }
  .navbar-nav-links-main {
    width: 100%;
    display: none;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 20px;
  }
  .navbar-nav-links-main > a {
    text-align: center;
    border-bottom: 1px solid white;
    width: 100%;
    padding: 5px;
  }
  .navbar-nav-links-main > a:hover {
    background-color: rgba(128, 128, 128, 0.508);
  }
  .menu.open .navbar-nav-links-main {
    display: flex;
  }
}
/* KeywordHeader.css */

/* Default style for NavLink elements */
.navbar-nav-links-main a {
  text-decoration: none; /* Remove underline */
  border-bottom: 2px solid transparent; /* Default border (transparent) */
}

/* Style for active NavLink elements */
.navbar-nav-links-main a.active {
  color: #e2534c; /* Active text color */
  border-color: #e2534c; /* Active border color */
}
