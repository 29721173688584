.livedarshan-main {
  width: 100%;
  padding-bottom: 50px;
}
.livedarshan-main1 {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.livedarshan-main-button {
  width: 68%;
  margin: auto;
}
.livedarshan-main-button > button {
  width: 100%;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.livedarshan-main-button > button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.livedarshan-main-video {
  width: 68%;
  height: 580px;
  margin: auto;
  background: #000000;
  overflow: auto;
}
