.contact-main {
  width: 100%;
  padding-bottom: 50px;
}
/* .contact-main1 {
  width: 70%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
} */
.contact-main-details {
  display: flex;
  flex-direction: column;
  gap: px;
}

@media screen and (max-width: 480px) {
  .contact-main1 {
      padding:16;
      width: 90%;
      margin: auto;
      margin-top: 2px;
      /* display: flex; */
      /* justify-content: space-between; */
    }
}

@media screen and (min-width: 480px) {
  .contact-main1 {
    width: 90%;
    margin-left: 16px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
}

/* @media screen and (min-width: 480px) {
  .contact-main1 {
    width: 70%;
    margin: auto;
    margin-top: 50px;
    display:  flex;
    justify-content: space-between;
  }
} */