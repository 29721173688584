.homePage-main {
  width: 100%;
  padding: 25px;
  /* padding-top: 0px; */
}
.homePage-main-his {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  text-align: center;
}
.homePage-main1 {
  width: 75%;
  padding: 20px;
  margin: auto;
}
.homePage-main1 > p {
  font-size: 16px;
  color: #666;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 0px 0px 16px;
}
/* donation css........................................................... */
.donation-main {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  /* border: 1px solid red; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.donation-main1 {
  width: 80%;
  margin: auto;
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
}
.donation-main1 div:nth-child(1) {
  background: black;
  padding: 50px;
  width: 380px;
  height: 291.2px;
}

.donation-main1 div:nth-child(2) {
  background: #ecebeb;
  padding: 50px;
  width: 380px;
  height: 291.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 30px; */
}
.donation-main1 div:nth-child(2) > img {
  height: 50px;
  vertical-align: middle;
  border-style: none;
}
.donation-main1 div:nth-child(2) > p {
  color: #000;
  letter-spacing: 0.5px;
  font-size: 28px;
  margin: 30px 0px;
  font-weight: 300;
  /* margin-top: 30px;
  margin-bottom: 30px; */
}
.donation-main1 div:nth-child(2) > button {
  background-color: #007bff;
  color: white;
  font-size: 20px;
  padding: 8px 30px;
  letter-spacing: 0.5px;
  font-weight: 300;
  padding: 8px 30px;
  border-radius: 10px;
}
.donation-main1 div:nth-child(3) {
  background: #f4f3f3;
  padding: 50px;
  width: 380px;
  height: 291.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.donation-main1 div:nth-child(3) > img {
  height: 50px;
  vertical-align: middle;
  border-style: none;
}
.donation-main1 div:nth-child(3) > p {
  color: #000;
  letter-spacing: 0.5px;
  font-size: 28px;
  margin: 30px 0px;
  font-weight: 300;
  /* margin-top: 30px;
    margin-bottom: 30px; */
}
.donation-main1 div:nth-child(3) > button {
  background-color: #007bff;
  color: white;
  font-size: 20px;
  padding: 8px 30px;
  letter-spacing: 0.5px;
  font-weight: 300;
  padding: 8px 30px;
  border-radius: 10px;
}

/* Daily Chart css........................................................... */

.DailyChart-main {
  width: 100%;
  padding: 20px;
  /* border: 1px solid red; */
}
.DailyChart-main1 {
  width: 16%;
  height: 100%;
  margin: auto;
  /* border: 1px solid red; */
}
.daily-prog {
  width: 100%;
  display: flex;
  gap: 10px;
  position: relative;
}
.daily-prog > p {
  font-size: 26px;
  margin: 0px 10px 0px 0px;
  color: #e2534c;
  font-weight: 300;
}
.daily-progdetails {
  display: flex;
  flex-direction: column;
}
.daily-progdetails1 {
  display: flex;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.daily-progdetails2 {
  display: flex;
  background-color: #f2f2f2;
}

.daily-progdetails1 > p {
  font-size: 16px;
  padding: 12px;
  font-weight: bold;
}
.daily-progdetails2 > p {
  font-size: 16px;
  padding: 12px;
  font-weight: bold;
}
.daily-progdetails1Black {
  background-color: #343a40;
  display: flex;
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
  font-size: 16px;
  /* padding: 12px; */
  margin-top: 40px;
  padding-left: 5px;
}
.daily-progdetails1Black > p {
  color: #fff;
  font-size: 16px;
  padding: 12px;
  font-weight: bold;
}
.daily-prog::after {
  position: absolute;
  content: "";
  background-color: #000;
  height: 1px;
  width: 90px;
  left: 0;
  bottom: -15px;
}
@media (max-width: 970px) {
  .DailyChart-main1 {
    width: 100%;
    height: 100%;
    margin: auto;
    /* border: 1px solid red; */
  }
}
