.Header-main {
  width: 100%;
  background-color: #f0f8ff;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}
.Header-main > p {
  width: 75%;
  margin: auto;
  color: #212529;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
@media (max-width: 768px) {
  .Header-main > p {
    width: 98%;
  }
}


.marquee-container {
  height: 30px;
  overflow: hidden;
  line-height: 30px;

  .marquee {
    top: 0;
    left: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    animation: marquee 30s linear infinite;
  }

  .marquee2 {
    animation-delay: 15s;
  }
  b {
    padding-left: 10px;
  }
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%
  }
}