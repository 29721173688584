.gallery-main {
  width: 100%;
  padding-bottom: 24px;
}
.gallery-main-1 {
  width: 70%;
  margin: auto;
  margin-top: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.gallery-main-1 > img {
  width: 360px;
  height: 250px;
}
