.Footer-main {
  width: 100%;
  background: #333333;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 6px;
}
.Footer-main-1 {
  display: flex;
  gap: 4px;
  justify-content: center;
}
.Footer-main-2 {
  display: flex;
  gap: 4px;
  justify-content: center;
}
.Footer-main-1 span:first-child {
  color: #E62375;
}

.Footer-main-2 span:first-child {
  color: #f8f4f3;
}

@media (max-width: 970px) {
  .Footer-main-1 {
    display: flex;
    /* gap: 30px; */
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Footer-main{
    padding: 3px;
  }
}
