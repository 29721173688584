.AboutPage-main {
  width: 100%;
  padding-bottom: 50px;
}
.about_us_details_hindi{
  background-color: #f1f1f1;
    width: 70%;
    height: fit-content; 
    margin: auto;
    margin-top: 40px;
    display: flex;
    padding: 30px;
    gap: 30px;
}

.about_us_details_English{
  background-color: #f1f1f1;
    width: 70%;
    height: fit-content;
    margin: auto;
    margin-top: 40px;
    display: flex;
    padding: 30px;
    gap: 30px;
}

.about_us_details_hindi p:nth-child(1) {
  font-size: 20px;
  color: #e2534c;
  font-weight: 400;
}
/* .about_us_details_English p:nth-child(1) {
  font-size: 20px;
  color: #e2534c;
  font-weight: 400;
}*/
.about_us_heading{
  font-size: 20px;
    color: #e2534c;
    font-weight: 400;
}

